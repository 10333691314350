import React, { useState, useEffect } from "react";
import "../style/botstyle.css";
import { useNavigate } from "react-router-dom";
import { symbol } from "prop-types";
import Cookies from 'js-cookie';

const Dashboard = () => {
  const [kotak, setKotak] = useState(false);
  const [ktflag, setKtflag] = useState(0);
  const [zerodha, setZerodha] = useState("");
  const [error, setError] = useState("");
  const [otp, setOtp] = useState("");
  const [token, setToken] = useState("");
  const [calcvalue, setCalcvalue] = useState([]);
  const[viewdetail,setviewdetail]= useState(false)
  const [startclicked,setStartclicked]= useState(false)
  const [terminal,setterminal]= useState(false)


  const [data, setData] = useState([
  

    { id: 2, label: "EMA1", value: "" },
    { id: 3, label: "EMA2", value: "" },
  ]);
  const [expiry, setExpiry] = useState([
    { id: 1, label: "Year", value: "" },

    { id: 2, label: "MONTH", value: "" },
    { id: 3, label: "WEEKLY", value: "" },
    { id: 4, label: "MONTHLY", value: "" },
   
  ]);
console.log(expiry)
const [block1, setBlock1]=  useState([ {
  id: 1,
  name: "",
  TMF: "",
  ITM1: false,
  ITM2: false,
  ITM3: false,
  ITM4: false,
  LOT: "",
  PROFIT102: "",
  PROFIT703: "",
  TRAIL: "",
  PROFIT:"",
  UPDATEFLAG: -1,
  START: 0,
  STOP: 0,
},
])

  const [block, setBlock] = useState([
    {
      id: 1,
      name: "",
      TMF: "",
      LOT: "",
      PROFIT102: "",
      PROFIT703: "",
      TRAIL: "",
      PROFIT:"",
      UPDATEFLAG: -1,
      START: 0,
      STOP: 0,
    },
  ]);

  const [supertrenddata, setSupertrenddata] = useState([
    {
      id: 1,
      name: "",
      TMF: "",
      ITM1: false,
      ITM2: false,
      ITM3: false,
      ITM4: false,
    },
  ]);

  console.log(data);
  console.log(block);

  const [optionwindow, setOptionwindow] = useState(false);
  const [expirywindow, setExpirywindow] = useState(false);
  const [ENTRYDELAY, setENTRYDELAY] = useState("");
  const [EMA1, setEMA1] = useState("");
  const [EMA2, setEMA2] = useState("");
  const [SUPERTREND_1P, setSUPERTREND_1P] = useState("");
  const [SUPERTREND_2P, setSUPERTREND_2P] = useState("");
  const [SUPERTREND_1M, setSUPERTREND_1M] = useState("");
  const [SUPERTREND_2M, setSUPERTREND_2M] = useState("");
  const [positiondata, setpositiondata] = useState([]);


  const [url, setUrl] = useState("");

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const Mobile= screenSize.width<1025
    const below740 =window.innerHeight<740
    const shortscren = screenSize.width<374
    const finalheigth = below740?screenSize.height+690:1500

  const handlePasswordChange = (e) => {
    setOtp(e.target.value);
  };

  

  const HANDLELOGOUT = () => {
    const allCookies = Cookies.get();
    const cookieNames = Object.keys(allCookies);
    console.log('Cookie names:', cookieNames);



    localStorage.removeItem('token')
    Cookies.remove("sessionid",{ path: '/', domain: '.shanitrader.in' })
    Cookies.remove("csrftoken",{ path: '/', domain: '.shanitrader.in' })

    // window.location.reload();
    navigate('/')

    
  };

  const brokercol = {
    backgroundColor: "#FF1E62",
  };
  const brokercol1 = {
    backgroundColor: "green",
  };
  // useEffect(() => {

  //   const sdd= localStorage.getItem('token')
  //       setToken(sdd)

  // }, [setToken])
  const HANDLEBLOCKUPDATESTART = (id) => {
    setBlock((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, START: 1 } : item
      )
    );}
  const HANDLEBLOCKUPDATESTOP = (id) => {
      setBlock((prevData) =>
        prevData.map((item) =>
          item.id === id ? { ...item, STOP: 1 } : item
        )
      );}



  const navigate = useNavigate();
  
  const handlekotakinit = () => {
    setKotak(true);
    const client= 1
    const payload = JSON.stringify({client});
    const type = "POST";
    const endpoint = "api/kotak";
    handleexchangerequest(type, payload, endpoint);
  };
  const handlezerodhainit = () => {
    const payload = "";
    const type = "POST";
    const endpoint = "data/api/zerodha";
    handleexchangerequest(type, payload, endpoint);
  };

  const handlestart = (id) => {
    
    const payload = "?client=1&id=" + id;
    const type = "GET";
    const endpoint = "api/startblock";
    handleexchangerequest(type, payload, endpoint);
    window.location.reload()
  };
  const handlestop = (id) => {
    
    const payload = "?client=1&id=" + id;
    const type = "GET";
    const endpoint = "api/stopblock";
    handleexchangerequest(type, payload, endpoint);
    window.location.reload()
  };
  const handlecalcvalue = () => {
    const payload = "";
    const type = "GET";
    const endpoint = "api/calcvalues?client=1";
    handleexchangerequest(type, payload, endpoint);
  };

  const positionall= ()=>{
    
    const payload = "";
    const type = "GET";
    const endpoint = "api/openposition?client=1";
    handleexchangerequest(type, payload, endpoint);

    
  }

  useEffect(() => {
    handlecalcvalue();
    handleexchangeflag();
    gettradingblock()
    positionall()
  }, []);

  const handleexchangeflag = () => {
    const client= 1
    const payload = "";
    const type = "GET";
    const endpoint = "api/exchange?client=1";
    handleexchangerequest(type, payload, endpoint);
  };
  const handleCloseTable = () => {
    setOptionwindow(false);
  };
  const handleCloseexpiry = () => {
    setExpirywindow(false);
  };
  const postsettings = () => {
    const client=1
    const payload = JSON.stringify(data,{client});
    const type = "POST";
    const endpoint = "api/settings?client=1";
    handleexchangerequest(type, payload, endpoint);
    window.location.reload();
  };
  
  const gettradingblock= ()=>{
    const payload = "";
    const type = "GET";
    const endpoint = "api/TradingBlock?client=1";
    handleexchangerequest(type, payload, endpoint);

  }
  
  const getsettings = () => {
    const payload = "";
    const type = "GET";
    const endpoint = "api/settings?client=1";
    handleexchangerequest(type, payload, endpoint);
  };

  const getexpiry = () => {
    const payload = "";
    const type = "GET";
    const endpoint = "api/expiry?client=1";
    handleexchangerequest(type, payload, endpoint);
  };
  const handleexit = (buyorderid,side,lot,symbol)=>{
    const client= 1
    const payload = JSON.stringify({ buyorderid,side,lot,client,symbol });
    const type = "POST";
    const exchange = "api/openposition";
    handleexchangerequest(type, payload, exchange);
    window.location.reload();



  }




  const postexpiry = () => {
    const client= 1
    const payload = JSON.stringify(expiry);
    const type = "POST";
    const endpoint = "api/expiry?client=1";
    handleexchangerequest(type, payload, endpoint);
    window.location.reload();
  };

  const HANDLETradingBlockUPDATE = (id) => {
    setBlock((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, UPDATEFLAG: 1 } : item
      )
    );
    const client=1
    const payload = JSON.stringify(block);
    const type = "POST";
    const endpoint = "api/TradingBlock?client=1";
    handleexchangerequest(type, payload, endpoint);
    window.location.reload();
  };

  const handleexchangerequest = async (type, payload, endpoint) => {
    const sdd = localStorage.getItem("token");
    try {
      const t = "token " + sdd;

      if (type === "POST") {
        const response = await fetch("https://shanitrader.in/" + endpoint, {
          method: type,
          headers: {
            "Content-Type": "application/json",
            Authorization: t,
          },
          body: payload,
        });
        if (!response.ok) {
          throw new Error("Login failed");
        }
        const datastr = await response.json();

        console.log(datastr);
        if (endpoint === "data/api/zerodha" ) {
          setUrl(datastr.message);
          console.log(datastr.message);
          window.open(datastr.message);
        }
      }
      if (type === "PUT") {
        const response = await fetch("https://shanitrader.in/" + endpoint, {
          method: type,
          headers: {
            "Content-Type": "application/json",
            Authorization: t,
          },
          body: payload,
        });
        if (!response.ok) {
          throw new Error("Login failed");
        }
        const datastr = await response.json();

        console.log(data);
        if (endpoint === "data/api/zerodha" ) {
          setUrl(datastr.message);
          console.log(datastr.message);
          // window.open(datastr.message);
        }
      }

      if (type === "GET" || "DELETE") {
        const response = await fetch(
          "https://shanitrader.in/" + endpoint + payload,
          {
            method: type,
            headers: {  
              "Content-Type": "application/json",
              Authorization: t,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Login failed");
        }
        const datastr = await response.json();

        if (endpoint === "api/settings?client=1" && type === "GET") {
          setData(datastr.Message);
          console.log(datastr.Message, ".............");
        }
        if (endpoint === "api/TradingBlock?client=1" && type === "GET") {
          setBlock1(datastr.Message);
          console.log(datastr.Message, "*****");
        }
        if (endpoint === "api/calcvalues?client=1" && type === "GET") {
          setCalcvalue(datastr.message);
          console.log(datastr.message, "...........");
        }
        if (endpoint === "api/exchange?client=1" && type === "GET") {
          setKtflag(datastr.message.kotakflag);
          setZerodha(datastr.message.zerodhaflag);
          setterminal(datastr.message.termial)
        }
        if (endpoint === "api/expiry?client=1" && type === "GET") {
          setExpiry(datastr.message);
        

       
        }
        if (endpoint === "api/openposition?client=1" && type === "GET") {
          setpositiondata(datastr.message);
          
        

       
        }

        
        
      }

      // if (!response.ok) {
      //   throw new Error('Login failed');
      // }
      // const data = await response.json()
      console.log(data);
    } catch (error) {
      setError("something went wrong");
      console.error("INIT error:", error);
    }

    // Reset the form after submission
  };

  const handlekotakotp = () => {
    const client= 1
    const payload = JSON.stringify({ otp,client });
    const type = "PUT";
    const exchange = "api/kotak";

    handleexchangerequest(type, payload, exchange);
    // window.location.reload()

    // Reset the form after submission
  };
  const handleoptions = () => {
    setOptionwindow(true);
    getsettings();
  };

  const handleexpiry = () => {
  setExpirywindow(true);
  getexpiry()

  };
  const handleCheckboxChange = (id, newValue) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, value: newValue } : item
      )
    );

    // const newlySelectedItems = newData.filter((item) => item.selected).map((item) => item.name);
  };

  const handleEXPIRY = (id, newValue) => {
    setExpiry((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, value: newValue } : item
      )
    );

    // const newlySelectedItems = newData.filter((item) => item.selected).map((item) => item.name);
  };
  const handlenamechange = (id, newValue) => {
    setBlock((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, name: newValue } : item
      )
    );
  };
  const handletmfchange = (id, newValue) => {
    setBlock((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, TMF: newValue } : item
      )
    );
  };
  const handleitm1change = (id) => {
    setBlock((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, ITM1: !item.ITM1 } : item
      )
    );
  };

  const handleitm2change = (id) => {
    setBlock((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, ITM2: !item.ITM2 } : item
      )
    );
  };

  const handleYear = (id, newValue) => {
    setExpiry((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, value: newValue } : item
      )
    );
  };
  const handlemonth = (id, newValue) => {
    setExpiry((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, value: newValue } : item
      )
    );
  };
  const handleWEEKLY = (id,newval) => {
    setExpiry((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, value : newval } : item
      )
    );
  };

  const handleMONTHLY = (id,newval) => {
    setExpiry((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, value: newval } : item
      )
    );
  };
  const handleitm3change = (id) => {
    setBlock((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, ITM3: !item.ITM3 } : item
      )
    );
  };
  const handleitm4change = (id) => {
    setBlock((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, ITM4: !item.ITM4 } : item
      )
    );
  };
  const handlelotchange = (id, newValue) => {
    setBlock((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, LOT: newValue } : item
      )
    );
  };
  const handleprofitchange = (id, newValue) => {
    setBlock((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, PROFIT102: newValue } : item
      )
    );
  };
  const handletProfitchange = (id, newValue) => {
    setBlock((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, PROFIT: newValue } : item
      )
    );
  };

  const handleSLchange = (id, newValue) => {
    setBlock((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, TRAIL: newValue } : item
      )
    );
  };
  const handleTSLchange = (id, newValue) => {
    setBlock((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, PROFIT703: newValue } : item
      )
    );
  };
  useEffect(()=>{
    {if (block1 !=[]){  
      console.log(block1,'!@')
      {if (block1[0]['START']==1){
        HANDLEBLOCKUPDATESTART(1)
      }}
      
      
      
      
      
      
    
      {if (block1[0]['STOP']===1){
        HANDLEBLOCKUPDATESTOP(1)
      }}
      
      
      
      
      
      
    }
    }
  },[block1])
  const handlemainterminal=()=>{
    setterminal(!terminal)
  const client= 1
    const switcht= !terminal
    const payload = JSON.stringify({ switcht,client });
    const type = "POST";
    const exchange = "api/pr1";
     handleexchangerequest(type, payload, exchange);
    window.location.reload();

  }

  const HANDLEPR2 = ()=>{
    window.open("/dashboard2")
  }
  useEffect(() => {
    const interval = setInterval(() => {
    handlecalcvalue()
    }, 15000);


    
    return () => clearInterval(interval);
  }, []); 

  return (
    <div className= 'main-container'>
      <div className="maintabs">

      <div className="tabs-container">
        
        <div className="tabs-box">
          <div className="tabs-row">
            <button onClick={() => handleexpiry()}>Expiry</button>

            <button onClick={handleoptions}>options</button>
            <button
              onClick={handlekotakinit}
              style={!ktflag ? brokercol : brokercol1}
            >
              kotak
            </button>
            <button
              onClick={handlezerodhainit}
              style={!zerodha ? brokercol : brokercol1}
            >
              zerodha
            </button>
            <button onClick={ HANDLELOGOUT}>
      Logout
    </button>
            <button onClick={ HANDLEPR2}>PROJECT2
            </button>
           
            <button onClick={ handlemainterminal}
            style={!terminal ? brokercol : brokercol1}>switch
            </button>



            {/* <button style={brokercol}>
        stop
    </button> */}
          </div>
          
          {kotak ? (
            <div>
              <form className="otp-form">
                <label>ENTER OTP</label>
                <input
                  type="password"
                  value={otp}
                  onChange={handlePasswordChange}
                  required
                />

                <button type="submit" onClick={handlekotakotp}>
                  SUBMIT
                </button>
              </form>
            </div>
          ) : (
            ""
          )}

          {error ? <p>{error}</p> : <p></p>}
        </div>
      </div>
      </div>


      <div className="secondcol">
        <div
          className={"settings-container"}
          style={{ height: "130px", overflow: "hidden" }}
        >
          <button onClick={()=>setviewdetail(true)}>VIEW DETAIL</button>
          {viewdetail?(
           
               <div className="small-window">
                 <button onClick={()=>setviewdetail(false)}>X</button>
                 <table className="settings-tablem">
            <thead>
              <tr>
                <th style={{width:"15%"}}> Name </th>
                <th style={{width:"15%"}}>TMF</th>
                <th style={{width:"12%"}}>LOT</th>
                <th style={{width:"12%"}}>TARGET</th>
                <th style={{width:"12%"}}>STOP LOSS </th>
                <th style={{width:"12%"}}>TRAIL</th>
                <th style={{width:"12%"}}>PROFIT</th>

                
              </tr>
            </thead>
            <tbody>
              {block1.map((item) => (
                <tr  key= {item.id}className="settings-tablem">
                  <td>
                   {item.name}
                  </td>
                  <td>
                  {item.TMF}
                  </td>
                  <td className="short-url-cell">
                    <input
                      type="number"
                      value={item.LOT}
                   
                      required
                    />
                  </td>
                  <td className="short-url-cell">
                    <input
                      type="number"
                      value={item.PROFIT102}
                     
                      required
                    />
                  </td>
                  <td className="short-url-cell">
                    <input
                      type="number"
                      value={item.PROFIT703}
                      
                      required
                    />
                  </td>
                  <td className="short-url-cell">
                    <input
                      type="number"
                      value={item.TRAIL}
                    
                      required
                    />
                  </td>
                  <td className="short-url-cell">
                    <input
                      type="number"
                      value={item.PROFIT}
                    
                      required
                    />
                  </td>

                             
                </tr>
              ))}
            </tbody>
          </table>
                </div>

          ):('')}
          <table className="settings-tablem">
            <thead>
              <tr>
                <th style={{width:"15%"}}> Name </th>
                <th style={{width:"15%"}}>TMF</th>
                <th style={{width:"12%"}}>LOT</th>
                <th style={{width:"12%"}}>TARGET</th>
                <th style={{width:"12%"}}>STOP LOSS</th>
                <th style={{width:"12%"}}>TRAIL</th>
                <th style={{width:"12%"}}>PROFIT</th>
                <th style={{width:"10%"}}>ACTION</th>
                <th style={{width:"10%"}}>START</th>
                <th style={{width:"10%"}}>STOP</th>
              </tr>
            </thead>
            <tbody>
              {block.map((item) => (
                <tr className="settings-tablem">
                  <td>
                    {" "}
                    <select
                      onChange={(e) =>
                        handlenamechange(item.id, e.target.value)
                      }
                      name="INDEX"
                    >
                      <option value=""> select</option>
                      <option value="BANKNIFTY">BANKNIFTY </option>
                      <option value="NIFTY">NIFTY </option>
                      <option value="FINNIFTY">FINNIFTY </option>
                      <option value="SENSEX">SENSEX </option>


                      {/* <option value="NIFTY" >NIFTY </option>
                      <option name="FINNIFTY" >FINNIFTY </option> */}
                    </select>
                  </td>
                  <td>
                    <select
                      onChange={(e) => handletmfchange(item.id, e.target.value)}
                      name="TMF"
                    >
                      <option value=""> select</option>
                      <option value="5m">5m </option>
                      <option value="10m">10m </option>
                      <option value="15m">15m </option>
                      <option value="30m">30m </option>
                    </select>
                  </td>
                  <td className="short-url-cell">
                    <input
                      type="number"
                      value={item.LOT}
                      onChange={(e) => handlelotchange(item.id, e.target.value)}
                      required
                    />
                  </td>
                  <td className="short-url-cell">
                    <input
                      type="number"
                      value={item.PROFIT102}
                      onChange={(e) =>
                        handleprofitchange(item.id, e.target.value)
                      }
                      required
                    />
                  </td>
                  <td className="short-url-cell">
                    <input
                      type="number"
                      value={item.PROFIT703}
                      onChange={(e) => handleTSLchange(item.id, e.target.value)}
                      required
                    />
                  </td>
                  <td className="short-url-cell">
                    <input
                      type="number"
                      value={item.TRAIL}
                      onChange={(e) => handleSLchange(item.id, e.target.value)}
                      required
                    />
                  </td>
                  <td className="short-url-cell">
                    <input
                      type="number"
                      value={item.PROFIT}
                      onChange={(e) => handletProfitchange(item.id, e.target.value)}
                      required
                    />
                  </td>

                  <td className="short-url-cell">
                    <button
                      onClick={() => HANDLETradingBlockUPDATE(item.id)}
                      type="submit"
                      
                    >
                      UPDATE
                    </button>
                  </td>
                  <td className="short-url-cell">
                    <button
                      onClick={() => item.START?'': handlestart(item.id)}
                      type="submit"
                      
                      style={item.START?brokercol1:brokercol}
                    >
                      START
                    </button>
                  </td>
                  <td className="short-url-cell">
                    <button
                      onClick={() => handlestop(item.id)}
                      type="submit"
                     
                      style={item.STOP?brokercol1:brokercol}
                    >
                      STOP
                    </button>
                   
                  </td>
                 
                </tr>
              ))}
            </tbody>
          </table>
          
        </div>
        <div
          className={"supertrend-container"}
          style={{ overflow: "hidden" }}
        >
          <table className="settings-tablem">
            <thead>
              <tr>
                <th> Name </th>
                <th>CE</th>
                <th>PE</th>
                <th>EMA3</th>
                <th>EMA5</th>
                
                
              </tr>
            </thead>
            <tbody>
              {calcvalue.map((item) => (
                <tr key={item.id} className="settings-tablem">
                  <td className="short-url-cell" >BANKNIFTY </td>
                  <td className="short-url-cell"> {item.CE}</td>
                  <td className="short-url-cell">{item.PE} </td>
                  <td className="short-url-cell" >{item.EMA1} </td>
                  <td className="short-url-cell"> {item.EMA2} </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div
        className={"project-details-container"}
        style={{ height: "200px", overflow: "hidden" }}
      >
        <table className="solid-tablem">
          <thead>
            <tr>
              <th> ID </th>
              <th>SIDE</th>
              <th>LOT</th>
              <th>status</th>
              <th>TARGET</th>
              <th>TRAIL</th>
              <th>symbol</th>
              <th>ACTION </th>
            </tr>
          </thead>
          <tbody>
            {positiondata.map((item) => (
              <tr  className="solid-table">
                <td className="short-url-cell">{item.buyorderid}</td>

                <td className="short-url-cell">{item.side}</td>
                <td className="short-url-cell">{item.lot}</td>
                <td className="short-url-cell">{item.status}</td>
                <td className="short-url-cell">{item.total_target}</td>
                <td className="short-url-cell">{item.trail}</td>
                <td className="short-url-cell">{item.symbol}</td>
                <td className="short-url-cell"><button onClick={()=>handleexit(item.buyorderid,item.side,item.lot,item.symbol)}>EXIT</button></td>


                {/* <td  className="short-url-cell"><button onClick={() => handleQRdownload(item)} type="submit"className = "qrbutton">getQR
                        </button></td> */}
              </tr>
            ))}
          </tbody>
        </table>

        {/* {isstart===index  ?<button    onClick = {  () =>  handlestartconfirm(data.name,data.ID) } className='deploy-cont-btn'>CONFIRM</button>:
      <button    onClick = {() =>  handlestart(index)} className='deploy-cont-btn'>START</button>}
      
      {isstop===index?<button    onClick = {() =>  handlestopconfirm(data.name) } className='deploy-cont-btn'>CONFIRM</button>:
      <button    onClick = {() =>  handlestop(index)} className='deploy-cont-btn'>STOP</button>}
     
      {exitindex===index?<button    onClick = {() =>  handleconfirmexit(data.ID,data.name)} className='deploy-cont-btn'>CONFIRM</button>:
      <button    onClick = {() =>  handleexit(index)} className='deploy-cont-btn'>EXIT</button>}
       */}
      </div>
      {optionwindow ? (
        <div className="small-window">
           <button onClick={handleCloseTable} >
                X
              </button>
          <table className="multi-tablem">
         
            <thead>
              <tr>
                <th>OPTIONS</th>
                <th>CURRENT</th>
                <th>NEW</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr>
                  <td>{item.label}</td>
                  <td>{item.value}</td>
                  <td>
                    {" "}
                    <input
                      onChange={(e) =>
                        handleCheckboxChange(item.id, e.target.value)
                      }
                      type=" text"
                    ></input>
                  </td>
                </tr>
              ))}
              <button onClick={postsettings} className="optionbuttton">
                UPDATE
              </button>
             
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
      {expirywindow ? (
        <div className="small-window">
          <button onClick={handleCloseexpiry} >
                X
              </button>
           <table className="multi-tablem">
            <thead>
              <tr>
                <th>OPTIONS</th>
                <th>CURRENT</th>
                <th>NEW</th>
              </tr>
            </thead>
            <tbody>
              {expiry.map((item) => (
                <tr >
                  <td>{item.label}</td>
                  <td>{item.value}</td>
                      
                  {item.id===1?(
                    <td>
                   <select
                   onChange={(e) =>
                     handleYear(item.id, e.target.value)
                   }
                   name="Year"
                 >
                  <option value=""> select</option>
                   <option value="2024">2024 </option>
                   <option value="2025">2025</option>
                   <option value="2026">2026</option>
                   <option value="2027">2027</option>
                   <option value="2028">2028</option>
                   <option value="2029">2029</option>
                   <option value="2030">2030</option>
                  
                 </select>
                 </td>

                  ):''} 
                   {item.id===2?(
                    <td>
                   <select
                   onChange={(e) =>
                    handlemonth(item.id, e.target.value)
                   }
                   name="MONTH"
                 >
                  <option value=""> select</option>
                   <option value="JAN"> January</option>
                   <option value="FEB">Febuary</option>
                   <option value="MAR">March</option>
                   <option value="APR">April</option>
                   <option value="MAY">May</option>
                   <option value="JUN">June</option>
                   <option value="JUL">July</option>
                   <option value="AUG">August</option>
                   <option value="SEP">September</option>
                   <option value="OCT">October</option>
                   <option value="NOV">Nov</option>
                   <option value="DEC">Dec</option>
                  
                 </select>
                 </td>

                  ):''} 
                  {item.id===3?(
                    <td>
                   <select
                   onChange={(e) =>
                    handleWEEKLY(item.id, e.target.value)
                   }
                   name="MONTHLY"
                 >
                  <option value=""> select</option>
                   <option value="01"> 1</option>
                   <option value="02">2</option>
                   <option value="03">3</option>
                   <option value="04">4</option>
                   <option value="05">5</option>
                   <option value="06">6</option>
                   <option value="07">7</option>
                   <option value="08">8</option>
                   <option value="09">9</option>
                   <option value="10">10</option>
                   <option value="11">11</option>
                   <option value="12">12</option>
                   <option value="13">13</option>
                   <option value="14">14</option>
                   <option value="15">15</option>
                   <option value="16">16</option>
                   <option value="17">17</option>
                   <option value="18">18</option>
                   <option value="19">19</option>
                   <option value="20">20</option>
                   <option value="21">21</option>
                   <option value="22">22</option>
                   <option value="23">23</option>
                   <option value="24">24</option>
                   <option value="25">25</option>
                   <option value="26">26</option>
                   <option value="27">27</option>
                   <option value="28">28</option>
                   <option value="29">29</option>
                   <option value="30">30</option>
                   <option value="31">31</option>
                  
                 </select>
                 </td>

                  ):''} 
                   {item.id===4?(
                    <td>
                   <select
                   onChange={(e) =>
                    handleMONTHLY(item.id, e.target.value)
                   }
                   name="WEEKLY"
                 >
                <option value=""> select</option>
               <option value="01"> 1</option>
                   <option value="02">2</option>
                   <option value="03">3</option>
                   <option value="04">4</option>
                   <option value="05">5</option>
                   <option value="06">6</option>
                   <option value="07">7</option>
                   <option value="08">8</option>
                   <option value="09">9</option>
                   <option value="10">10</option>
                   <option value="11">11</option>
                   <option value="12">12</option>
                   <option value="13">13</option>
                   <option value="14">14</option>
                   <option value="15">15</option>
                   <option value="16">16</option>
                   <option value="17">17</option>
                   <option value="18">18</option>
                   <option value="19">19</option>
                   <option value="20">20</option>
                   <option value="21">21</option>
                   <option value="22">22</option>
                   <option value="23">23</option>
                   <option value="24">24</option>
                   <option value="25">25</option>
                   <option value="26">26</option>
                   <option value="27">27</option>
                   <option value="28">28</option>
                   <option value="29">29</option>
                   <option value="30">30</option>
                   <option value="31">31</option>
                  
                 </select>
                 </td>

                  ):''} 
                  
                  
                
                 
                </tr>
              ))}
              <button onClick={postexpiry} className="optionbuttton">
                UPDATE
              </button>
            </tbody>
          </table>
        
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Dashboard;
